import { Box, Typography } from "@mui/material";
import React from "react";

import cyberpunkBg from "../assets/images/cyberpunk-bg.png";


function HeroSection() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "100%",
        width: "100%",
        placeItems: "center", // centers both vertically and horizontally
        // px: 2,

        bgcolor: "background.default",
        backgroundImage: `url(${cyberpunkBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      {/* A box to add a tint to background */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent black tint
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", // Add this line
          // color: "accent.violet",

          px: 2,

          textAlign: { xs: "center", md: "start" },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            mb: 3,
            fontSize: "max(6vw, 4rem)",
            fontWeight: "bold",
            letterSpacing: "0.05em",
            fontFamily: "'Orbitron', sans-serif",
          }}
          className="glowing-text"
        >
          urban freestyler
        </Typography>

        {/* <Typography
          variant="h3"
          component="h3"
          gutterBottom
          sx={{ color: "white", maxWidth: 500 }}
        >
          A brief description that captures the essence
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          // sx={{ width: "150px" }}
        >
          Get Started
        </Button> */}
      </Box>
    </Box>
  );
}

export default HeroSection;
