// HomePage component
import { Box } from "@mui/material";
import React from "react";
import HeroSection from "../components/HeroSection";

const HomePage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <HeroSection />
      {/* <Box sx={{ minHeight: "100vh" }} /> */}
    </Box>
  );
};

export default HomePage;
