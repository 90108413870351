import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { Link as ScrollLink } from "react-scroll";

import logo from "../../assets/images/logo.png";

const navItems = [
  // { id: 1, name: "Home", link: "hero" },
  { id: 2, name: "Услуги", link: "services" },
  { id: 3, name: "О нас", link: "about" },
  { id: 4, name: "Процесс", link: "howwework" },
  { id: 5, name: "География", link: "wherewework" },
  { id: 6, name: "FAQ", link: "faq" },
  { id: 7, name: "Контакты", link: "contact" },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    display: "none",
    [theme.breakpoints?.down("sm")]: {
      display: "block",
    },
  },
  navLinks: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-around",
    [theme.breakpoints?.down("sm")]: {
      display: "none",
    },
  },
}));

const Navbar = React.memo(() => {
  const [scrollOpacity, setScrollOpacity] = useState(1); // Initial opacity
  const [prevScrollPos, setPrevScrollPos] = useState(0); // Track previous scroll position

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > prevScrollPos) {
        // User is scrolling down
        setScrollOpacity(0.1);
      } else {
        // User is scrolling up or at the top
        if (currentScrollPos <= 50) {
          // If the user is near the top, use the initial opacity
          setScrollOpacity(1);
        } else {
          setScrollOpacity(1); // Slightly higher opacity while scrolling up
        }
      }

      // Update the previous scroll position
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  const classes = useStyles();

  const list = () => (
    <Box
      sx={{ width: 250, pt: 4 }}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {navItems.map((item) => (
          <ListItem Button key={item.id}>
            <ScrollLink
              to={item.link}
              onClick={toggleDrawer()}
              smooth={true}
              duration={500}
              spy={true}
              offset={-70}
            >
              <ListItemText
                primary={item.name}
                disableTypography
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  textDecoration: "none",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              />
            </ScrollLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        opacity: scrollOpacity,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          {/* Logo */}
          <Typography
            variant="h6"
            component={ScrollLink}
            to="hero"
            smooth={true}
            duration={500}
            spy={true}
            offset={-70}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              flexGrow: 1,
              pt: 1,
            }}
          >
            <img src={logo} alt="Logo" style={{ height: "40px" }} />
          </Typography>

          {/* Navigation Links for Desktop */}
          <Box
            className={classes.navLinks}
            sx={{ display: { xs: "none", lg: "block" } }}
          >
            {navItems.map((item) => (
              <Button
                fontWeight="bold"
                component={ScrollLink}
                key={item.id}
                to={item.link}
                smooth={true}
                duration={500}
                spy={true}
                offset={-70}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  background: "transparent",
                  color: "white",
                  mixBlendMode: "exclusion",
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>

          {/* Hamburger Menu for Mobile */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer()}
            className={classes.menuButton}
            sx={{ display: { xs: "block", lg: "none" }, fontSize: 32, mt: 1 }}
          >
            <BiMenu />
          </IconButton>

          {/* Drawer */}
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer()}
            PaperProps={{
              style: {
                backdropFilter: "blur(10px)",
              },
              sx: {
                backgroundColor: "rgba(0, 0, 0, 0.4)", // Base opacity at 0.4
                backdropFilter: "blur(6px)",
                color: "white",
                position: "relative",
                p: 2,
              },
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close drawer"
              onClick={toggleDrawer()}
              sx={{
                position: "absolute",
                top: 10,
                right: 15,
                color: "#fff",
                "&:hover": {
                  color: "lightgray",
                },
              }}
            >
              <CgClose size={24} />
            </IconButton>

            {list()}
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default Navbar;
