import { createTheme } from "@mui/material/styles";

// Define the colors
const colors = {
  background: {
    dark: "#191A23", // Dominant dark background
    darker: "#181B23", // Slightly lighter dark shade
  },
  accent: {
    blue: "#00FFFF", // Neon blue
    violet: "#9400D3", // Neon violet
    pink: "#FF007F", // Neon pink
    teal: "#00FFD1", // Neon teal
  },
};

// Create the theme
const theme = createTheme({
  palette: {
    mode: "dark", // Enable dark mode
    background: {
      default: colors.background.dark,
      paper: colors.background.darker,
    },
    primary: {
      main: colors.accent.blue, // Neon blue as the primary color
    },
    secondary: {
      main: colors.accent.pink, // Neon pink as the secondary color
    },
    error: {
      main: colors.accent.violet, // Use violet for error accents
    },
    success: {
      main: colors.accent.teal, // Neon teal for success messages
    },
    text: {
      primary: "#FFFFFF", // White text
      secondary: colors.accent.teal, // Teal for secondary text
    },
  },
  typography: {
    fontFamily: "'Orbitron', sans-serif",
    h1: {
      fontFamily: "'Saira Stencil One', sans-serif",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "'Saira Stencil One', sans-serif",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "'Saira Stencil One', sans-serif",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "'Saira Stencil One', sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontFamily: "'Saira Stencil One', sans-serif",
      fontWeight: 700,
    },
    h6: {
      fontFamily: "'Saira Stencil One', sans-serif",
      fontWeight: 700,
    },

    button: {
      fontFamily: "'Orbitron', sans-serif",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded edges
          fontWeight: "bold",
          textTransform: "uppercase",
          backgroundColor: colors.accent.blue, // Default button color
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: colors.accent.violet, // Violet on hover
          },
        },
        outlined: {
          borderColor: colors.accent.pink,
          color: colors.accent.pink,
          "&:hover": {
            backgroundColor: colors.accent.teal,
            color: "#FFFFFF",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.accent.pink,
          "&:hover": {
            textDecoration: "underline",
            color: colors.accent.violet,
          },
        },
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       color: colors.accent.violet, // Default text color
    //     },
    //   },
    // },
  },
});

export default theme;
