import React from "react";
import Navbar from "./components/Navbar/Navbar";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import HomePage from "./pages/Home";

import theme from "./theme";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Navbar /> */}
      <HomePage />
    </ThemeProvider>
  );
}
